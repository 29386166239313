#games {
    background-color: black;

    .title-section {
        min-height: 35vh;
        background-image: url(../img/aboutUs/titleBg.png);
        background-size: 100% 100%;
        background-position: top;
        padding-top: 10vh;
    }

    .titleSectionText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 80px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 45px;
        }
    }

    .header-section {
        min-height: 100vh;
        background-image: url(../img/ourGames/headerBg.png);
        background-size: 100% 100%;
        background-position: top;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 992px) {
            background-size: cover;
            background-position: center;
        }
    }

    .what-we-do {
        min-height: 100vh;
        background-color: black;
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 70px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 70px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 22px;
        }
    }

    .gameListTableBg {
        // background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        // background-image: url(../img/ourGames/gameListBg.png);
        background: #000;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        border: 2px solid #5f5f5f;
    }

    .gameDetailsBg {
        background: rgba(217, 217, 217, 0.1);
    }

    .iconSize {
        width: 40px;

        @media (max-width: 677px) {
            width: 40px;
        }
    }

    .gameCatBorder {
        border-bottom: 2px solid #A18C68;
    }

    .learnMoreText {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 30px;
        color: #A28C68;
    }

    .sideItem1Pos-games {
        position: absolute;
        top: -7%;
        right: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem2Pos-games {
        position: absolute;
        top: 5%;
        left: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .gameList {
        img {
            width: 100%;
            height: 100%;
        }

        .hoverLayer {
            opacity: 0;
        }

        &:hover {
            .hoverLayer {
                opacity: 1;
                transition: 800ms;
            }
        }
    }

    .live-dealer {
        min-height: 100vh;
        background-image: url(../img/aboutUs/licensedByBg.png);
        background-size: cover;
        background-position: center;
    }

    .gameDetailsPos {
        bottom: 5%;
        left: 5%
    }

    .categoryTabTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;

        @media (max-width: 677px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .gameDetailsTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;

        @media (max-width: 677px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .gameDetailsBox {
        background: #050408;
        border-radius: 0px 0px 15px 15px;
        min-height: 120px;
        border: none;
        margin-top: -10px;
    }

    .gameDetailsShort {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 200;
        font-size: 12px;
        line-height: 140%;
        color: #FFFFFF;

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .gameDetailsLong {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 0.8vw;
        line-height: 140%;
        color: #FFFFFF;
        white-space: break-spaces;

        @media (max-width: 992px) {
            font-size: 16px;
        }

        @media (max-width: 677px) {
            font-size: 13px;
        }
    }

    .hoverLayer {
        background: rgba(5, 4, 8, 0.9);
        border-radius: 15px;
        top: 0;
    }

    .sideItem3Pos-games {
        position: absolute;
        top: 18%;
        right: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem4Pos-games {
        position: absolute;
        top: 23%;
        left: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem5Pos-games {
        position: absolute;
        top: 35%;
        left: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem6Pos-games {
        position: absolute;
        top: 49%;
        right: 0;

        img {
            @media (max-width: 992px) {
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem7Pos-games {
        position: absolute;
        top: 65%;
        left: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem8Pos-games {
        position: absolute;
        top: 87%;
        right: 6%;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.gameDetailsPopUpBg {
    background-color: #000;
    border-radius: 15px;
}

.popUpGameTitle {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #FFFFFF;

    @media (max-width: 677px) {
        font-size: 20px;
        line-height: 30px;
    }
}

.gameCategoryTab {
    background: #FFFFFF;
    border-radius: 50px;
    cursor: pointer;
    // width: 180px;
    margin: auto;

    @media (max-width: 357px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
    }

    span {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #050408;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 30px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 18px;
        }
    }
}

.gameCategoryActiveTab {
    background: #CAB48E;
    border-radius: 50px;
    cursor: pointer;
    // width: 180px;
    margin: auto;

    @media (max-width: 357px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
    }

    span {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #050408;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 30px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 18px;
        }
    }
}

.Wnext {
    position: absolute;
    top: 40%;
    right: -2%;
    cursor: pointer;
}

.Wprev {
    position: absolute;
    top: 40%;
    left: -2%;
    z-index: 99;
    cursor: pointer;
}

.popUpGameDesc {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 140%;
    color: #FFFFFF;

    @media (max-width: 677px) {
        font-size: 12px;
        line-height: 22px;
    }
}

.modalCloseBtn {
    position: absolute;
    top: 5%;
    right: 5%;
    color: #CAB48E;
    font-size: 20px;
}


#gameCatMenu {
    ul {
        background-color: black !important;
    }
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    background-color: black !important;
}

.MuiMenu-list {
    background-color: #000 !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: #f9c56b;
}