#news {
    background-color: black;

    .title-section {
        min-height: 35vh;
        background-image: url(../img/aboutUs/titleBg.png);
        background-size: 100% 100%;
        background-position: top;
        padding-top: 10vh;
    }

    .titleSectionText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 80px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .news-Bg {
        background-color: black;
        padding-top: 10vh;
        background-image: url(../img/news/featured_bg.png);
        background-size: cover;
    }

    .header-section {
        min-height: 100vh;
        background-image: url(../img/news/banner.png);
        background-size: 100% 100%;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 992px) {
            min-height: 50vh;

        }

        @media (max-width: 677px) {
            min-height: 30vh;

        }

    }

    .featuredVideos {}

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 50px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 45px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .newsTab {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 30px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 15px;
                line-height: 18px;
            }
        }
    }

    .newsActiveTab {
        background: #CAB48E;
        border-radius: 50px;
        cursor: pointer;

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 30px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 15px;
                line-height: 18px;
            }
        }
    }


    .brands-card {
        // background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        // backdrop-filter: blur(50px);
        // border-radius: 15px;
    }

    .newsCategory {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 36px;
        text-decoration-line: underline;
        color: #CAB48E;

        @media (max-width: 992px) {
            font-size: 22px;
            line-height: 36px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .newsTitle {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-transform: capitalize;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 22px;
            line-height: 30px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .newsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 22px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .newsAuthorPic {
        width: 30px;
        border-radius: 50%;

        @media (max-width: 677px) {
            width: 30px;
        }
    }

    .timeIconSize {
        width: 20px;
    }

    .newsDetailsDivider {
        width: 2px;
        height: 15px;
        background-color: #FFFFFF;
    }

    .newsBorderDivider {
        width: 100%;
        height: 2px;
        background: #A28C68;
    }

    .learnMoreBrandText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        color: #A28C68;
    }

    .learnMoreBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 220px;
        margin: auto;

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 50px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 50px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 40px;
            }
        }
    }

    .sideItem1Pos-news {
        position: absolute;
        top: 0%;
        right: 0;
        width: 30%;
        z-index: 0;

        img {
            z-index: 0;

            @media (max-width: 992px) {
                // font-size: 30px;
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem2Pos-news {
        position: absolute;
        top: 40%;
        left: 0;
        width: 30%;
        z-index: 0;

        img {
            z-index: 0;

            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .pagination {
        justify-content: center;
        backdrop-filter: blur(11.5px);
    }

    .pagination li {
        padding: 20px;
        // backdrop-filter: blur(11.5px);
        /* Note: backdrop-filter has minimal browser support */
        border-right: none;
        border-left: none;
        background: transparent !important;
        color: #42BFF5 !important;
        font-weight: bold;
        line-height: 30px;
        cursor: pointer;

        a {
            color: white;
        }
    }

    .previous {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .next {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .pagination__link--active {
        padding-left: 5px !important;
        padding-right: 5px !important;

        a {
            padding: 10px 15px;
            background: transparent;
            border-radius: 4px;
            color: #A28C68 !important;
        }
    }

    .pagination__nextlink {
        background-color: transparent;
        // border: 1px solid #42BFF5;
        // border-radius: 50%;
        font-size: 20px;
        font-weight: 500;
        color: #A28C68 !important;
        cursor: pointer;
    }

    .pagination__previouslink {
        background-color: transparent;
        // border: 1px solid #42BFF5;
        // border-radius: 50%;
        font-size: 20px;
        font-weight: 500;
        color: #A28C68 !important;
        cursor: pointer;
    }

    .newsImg {
        width: 100%;
        height: 35vh;
        object-fit: cover;

        @media (max-width: 677px) {
            height: 25vh;
        }
    }

    @keyframes square-animation {
        0% {
            left: 0;
            top: 0;
        }

        10.5% {
            left: 0;
            top: 0;
        }

        12.5% {
            left: 15px;
            top: 0;
        }

        23% {
            left: 15px;
            top: 0;
        }

        25% {
            left: 30px;
            top: 0;
        }

        35.5% {
            left: 30px;
            top: 0;
        }

        37.5% {
            left: 30px;
            top: 15px;
        }

        48% {
            left: 30px;
            top: 15px;
        }

        50% {
            left: 15px;
            top: 15px;
        }

        60.5% {
            left: 15px;
            top: 15px;
        }

        62.5% {
            left: 15px;
            top: 30px;
        }

        73% {
            left: 15px;
            top: 30px;
        }

        75% {
            left: 0;
            top: 30px;
        }

        85.5% {
            left: 0;
            top: 30px;
        }

        87.5% {
            left: 0;
            top: 15px;
        }

        98% {
            left: 0;
            top: 15px;
        }

        100% {
            left: 0;
            top: 0;
        }
    }

    .loader {
        position: relative;
        width: 25px;
        height: 25px;
        transform: rotate(45deg);
    }

    .loader-square {
        position: absolute;
        top: 0;
        left: 0;
        width: 13px;
        height: 13px;
        margin: 2px;
        border-radius: 0px;
        background: #A28C68;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        animation: square-animation 10s ease-in-out infinite both;
    }

    .loader-square:nth-of-type(0) {
        animation-delay: 0s;
    }

    .loader-square:nth-of-type(1) {
        animation-delay: -1.4285714286s;
    }

    .loader-square:nth-of-type(2) {
        animation-delay: -2.8571428571s;
    }

    .loader-square:nth-of-type(3) {
        animation-delay: -4.2857142857s;
    }

    .loader-square:nth-of-type(4) {
        animation-delay: -5.7142857143s;
    }

    .loader-square:nth-of-type(5) {
        animation-delay: -7.1428571429s;
    }

    .loader-square:nth-of-type(6) {
        animation-delay: -8.5714285714s;
    }

    .loader-square:nth-of-type(7) {
        animation-delay: -10s;
    }

    .newsDetailsTitle {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 25px;
        text-transform: capitalize;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 22px;
            line-height: 30px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .newsDetailsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 22px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }

        img {
            margin-bottom: 40px;
        }

        .featuredImage {
            display: none;
        }

        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 22px;
            }
    
            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 20px;
            }
        }

        h2 {
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;

            @media (max-width: 992px) {
                font-weight: 700;
                font-size: 22px;
                line-height: 30px;
            }
    
            @media (max-width: 677px) {
                font-weight: 700;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }

    .newsDetailsAuthorPic {
        width: 30px;
        border-radius: 50%;

        @media (max-width: 677px) {
            width: 30px;
        }
    }

    .timeIconSize {
        width: 20px;
    }

    .newsDetailsImg {
        width: 100%;
        height: 80vh;
        object-fit: cover;

        @media (max-width: 677px) {
            height: 25vh;
        }
    }
}