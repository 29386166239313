.navBarLogo {
    width: 120px;

    @media (max-width: 677px) {
        width: 90px;
    }
}

.navbar-default-color {
    background: red !important;
}

.navbar-yeebet {
    background: rgba(5, 4, 8, 0.35);
}

.navbar-yeebet2 {
    background: rgb(5, 4, 8);
}

.navbar-dark {
    background-color: black !important;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.textDecorationNone {
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.navItemText {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
}

.navItemDemoText {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #000;
}

.DemoDiv {
    background: #FFFFFF;
    border-radius: 50px;
    width: 120px;
    text-align: center;
    color: black;
    font-weight: bold;
}

.footer {
    background: #000;
    background-image: url(../img/home/footerBg.png);
    background-size: 100% 100%;

    @media (max-width: 992px) {
        background-size: cover;
        background-position: bottom;
    }

    @media (max-width: 677px) {
        background-size: cover;
        background-position: bottom;
    }
}

.footerLogoSize {
    width: 150px;
}

.footerNavText {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
}

.footerBorderBottom {
    border: 0.5px solid #CAB48E;
}

.footer-socialIcon {
    color: white;
    font-size: 20px;
}

.copywriteText {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    color: #FFFFFF;
}

.borderRightFooter {
    @media (max-width: 992px) {
        border-right: 1px solid #fff;
    }
}

// .borderBottomFooter {
//     @media (max-width: 992px) {
//         border-bottom: 1px solid #CAB48E;
//     }
// }

.sidebar-collapse .navbar-collapse:before {

    @media (max-width: 992px) {
        background: black;
    }
}

.dropdown-menu {
    background: rgba(196, 196, 196, 0.8);
    border-radius: 15px 15px 0 0;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
    color: #050408 !important;
}

.dropdown-item {
    &:hover {
        color: #fff !important;
    }
}

.dropdown-menu:before {
    color: rgba(196, 196, 196, 0.8);
    display: none;
}

.nav-item {
    .nav-link {

        &:focus,
        &:hover {
            border-radius: 25px;
            background-color: transparent !important;
        }
    }
}

@media screen and (max-width: 300px) {
    .sidebar-collapse [class*="navbar-expand-"] .navbar-collapse {
        width: 200px !important;
    }

    .nav-open .sidebar-collapse .navbar-translate {
        transform: translate3d(-200px, 0, 0);
    }

    .sidebar-collapse #bodyClick {
        right: 200px;
    }
}

.paddingNarrow {
    @media (max-width: 992px) {
        padding-left: 5px;
        padding-right: 5px;
    }
}