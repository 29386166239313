#ourPartner {
    .our-partner {
        min-height: 100vh;
        background-color: black;
        background-image: url(../img/partner/bg.png);
        background-size: cover;
        background-position: center;
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 50px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 45px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .gameCategoryTab {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        margin: auto;

        @media (max-width: 357px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
        }

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 30px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 15px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 13px;
                line-height: 18px;
            }
        }
    }

    .gameCategoryActiveTab {
        background: #CAB48E;
        border-radius: 50px;
        cursor: pointer;
        margin: auto;

        @media (max-width: 357px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
        }
        
        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 30px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 15px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 13px;
                line-height: 18px;
            }
        }
    }

    .text-inactive-gray {
        color: #565656;
    }

    .ourPartnerABC {
        font-weight: bold;
        font-size: 20px;
        word-wrap: break-word;
    }

    .GoldenBorder {
        border-bottom: 2px solid #CAB48E;
        width: fit-content;

        @media (max-width: 992px) {
            width: 100%;
        }
    }

    .partnerCatContainer {
        width: fit-content;
        margin: auto;

        @media (max-width: 992px) {
            width: 100%;
        }
    }

    .ourPartnerName {
        color: white;
        font-size: 17px;
        // word-wrap: break-word;
        @media (max-width: 992px) {
            display: block;
        }
    }

}