#brands {
    background-color: black;

    .title-section {
        min-height: 35vh;
        background-image: url(../img/aboutUs/titleBg.png);
        background-size: 100% 100%;
        background-position: top;
        padding-top: 10vh;
    }

    .titleSectionText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 80px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 50px;
        }
    }

    .brands-liveBg {
        background-color: black;
        background-image: url(../img/ourBrands/bodyBg.png);
        background-size: cover;
        background-position: center;
        padding-top: 15vh;
    }

    .header-section {
        min-height: 100vh;
        background-position: top;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 45px;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 22px;
        }
    }

    .brands-card {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
    }

    .brandsList-container {
        border-radius: 15px;
        background-image: url(../img/ourBrands/brandsitem-bg.png);
        background-size: cover;
        background-position: bottom right;
        padding-left: 30px;
        padding-right: 30px;
    }

    .minHeight-20 {
        min-height: 250px;
    }

    .learnMoreBrandText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        color: #A28C68;
    }

    .brandsList-logo {
        width: 40%;
        aspect-ratio: 2/1;
        object-fit: contain;

        @media (max-width: 992px) {
            width: 80px;
        }

        @media (max-width: 677px) {
            width: 140px;
        }
    }

    .brandsList-title {
        display: none;
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 52px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 32px;
            line-height: 52px;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 40px;
        }
    }

    .brandsList-desc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 1.1rem;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 16px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .sideItem1Pos-brands {
        position: absolute;
        top: 0%;
        left: 0;
        width: 30%;
        z-index: 0;

        img {
            z-index: 0;

            @media (max-width: 992px) {
                // font-size: 30px;
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem2Pos-brands {
        position: absolute;
        top: 50%;
        right: 0;
        width: 30%;
        z-index: 0;

        img {
            z-index: 0;

            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }
}