#home {
    background-color: black;

    .fullPageHeight {
        height: 100vh;
        width: 100%;

    }

    #myVideo {
        min-width: 100%;
        min-height: 100vh;
        object-fit: cover;
    }

    .videoOverlay {
        min-width: 100%;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
    }

    .overlaySectionTitle {
        color: #CAB48E;
        text-transform: uppercase;
        letter-spacing: .05em;
        outline: none;
        cursor: pointer;
        font-weight: bold;
        font-size: 40px;

        @media (max-width: 992px) {
            font-size: 35px;
        }

        @media (max-width: 677px) {
            font-size: 25px;
        }
    }

    .contactUsText {
        color: #CAB48E;
        text-transform: uppercase;
        letter-spacing: .25em;
        outline: none;
        cursor: pointer;
        font-weight: bold;
    }

    .headerBtn {
        --offset: 10px;
        --border-size: 2px;

        display: block;
        position: relative;
        padding: 1.5em 5em;
        appearance: none;
        border: 0;
        background: transparent;
        color: #CAB48E;
        text-transform: uppercase;
        letter-spacing: .25em;
        outline: none;
        cursor: pointer;
        font-weight: bold;
        border-radius: 0;
        box-shadow: inset 0 0 0 var(--border-size) currentcolor;
        transition: background .8s ease;

        &:hover {
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
        }

        .headerBtn_horizontal,
        .headerBtn_vertical {
            position: absolute;
            top: var(--horizontal-offset, 0);
            right: var(--vertical-offset, 0);
            bottom: var(--horizontal-offset, 0);
            left: var(--vertical-offset, 0);
            transition: transform .8s ease;
            will-change: transform;

            &::before {
                content: '';
                position: absolute;
                border: inherit;
            }
        }

        .headerBtn_horizontal {
            --vertical-offset: calc(var(--offset) * -1);
            border-top: var(--border-size) solid currentcolor;
            border-bottom: var(--border-size) solid currentcolor;

            &::before {
                top: calc(var(--vertical-offset) - var(--border-size));
                bottom: calc(var(--vertical-offset) - var(--border-size));
                left: calc(var(--vertical-offset) * -1);
                right: calc(var(--vertical-offset) * -1);
            }
        }

        &:hover {
            .headerBtn_horizontal {
                transform: scaleX(0);
            }
        }

        .headerBtn_vertical {
            --horizontal-offset: calc(var(--offset) * -1);
            border-left: var(--border-size) solid currentcolor;
            border-right: var(--border-size) solid currentcolor;

            &::before {
                top: calc(var(--horizontal-offset) * -1);
                bottom: calc(var(--horizontal-offset) * -1);
                left: calc(var(--horizontal-offset) - var(--border-size));
                right: calc(var(--horizontal-offset) - var(--border-size));
            }
        }

        &:hover {
            .headerBtn_vertical {
                transform: scaleY(0);
            }
        }

    }

    .scrollDownGif {
        width: 30px;
        animation-name: floating;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    @keyframes floating {
        0% {
            transform: translate(0px, 0px);
        }

        50% {
            transform: translate(0px, 30px);
        }

        100% {
            transform: translate(0px, 0px);
        }
    }

    .header-section {
        min-height: 100vh;
        background-image: url(../img/home/headerImg.png);
        background-size: cover;
        background-position: top;
    }

    .brandsBg {
        background-image: url(../img/home/brandsBg.png);
        // background-size: 100% 70%;
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;
    }

    .brandsBg2 {
        background-image: url(../img/home/brandsBg.png);
        // background-size: 100% 70%;
        background-size: 100% 50%;
        background-position: top;
        background-repeat: no-repeat;
    }

    .our-brands {
        min-height: auto;

        @media (max-width: 992px) {
            min-height: auto;
        }

        @media (max-width: 677px) {
            min-height: auto;
        }
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 50px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 45px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .gameGroupTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 50px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 45px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .gamingGroupDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 30px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 21px;
        }
    }

    .brandsLogo {
        width: 50%;
        aspect-ratio: 2/1;
        object-fit: contain;

        @media (max-width: 992px) {
            width: 70%;
        }

        @media (max-width: 677px) {
            width: 100%;
        }
    }

    .sideItem1Pos {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem2Pos {
        position: absolute;
        bottom: -30%;
        right: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .game-category {
        min-height: 100vh;

        @media (max-width: 992px) {
            min-height: auto;
            padding-bottom: 10vh;
        }

        @media (max-width: 677px) {
            min-height: auto;
            padding-bottom: 10vh;
        }
    }

    // .gameCategoryContent {
    //     padding-top: 50vh;

    //     @media (max-width: 992px) {
    //         padding-top: 10vh;
    //     }

    //     @media (max-width: 677px) {
    //         padding-top: 10vh;
    //     }
    // }

    .gameCategoryTab {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        // width: 180px;
        margin: auto;

        @media (max-width: 357px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
        }

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    .gameCategoryActiveTab {
        background: #CAB48E;
        border-radius: 50px;
        cursor: pointer;
        // width: 180px;
        margin: auto;

        @media (max-width: 357px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
        }

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    .gameCategoryTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
        }
    }

    .gameCategoryDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 13px;
            line-height: 30px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .gameCategoryDetailsPos {
        bottom: 5%;
        left: 5%;
    }

    .gamingGroupBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 180px;

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 13px;
                line-height: 40px;
            }
        }
    }

    .learnMoreBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 180px;
        margin: auto;

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 13px;
                line-height: 40px;
            }
        }
    }

    .gamingGroupPos {
        // position: absolute;
        // top: -35vh;
        // left: 0;
        // right: 0;
        // margin: auto;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 992px) {
            position: relative;
            min-height: 80vh;
        }

        @media (max-width: 677px) {
            position: relative;
            min-height: 100vh;
        }
    }

    .gamingGroupDiv {
        background-image: url(../img/home/gamingGroupBg.png);
        background-size: 100% 100%;
        background-position: center;

        @media (max-width: 992px) {
            background-image: url(../img/home/gamingGroupBg2.png);
            background-size: 100% 100%;
            padding: 0;
        }
    }

    .mobileLine {
        @media (max-width: 992px) {
            width: 80%;
        }
    }

    .sideItem3Pos {
        position: absolute;
        top: 0%;
        left: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem4Pos {
        position: absolute;
        bottom: -20%;
        right: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .our-partner {
        min-height: 100vh;
        background-image: url(../img/home/partnerBg.png);
        background-size: cover;
        background-position: top;

        @media (max-width: 992px) {
            min-height: auto;
        }
    }

    .sideItem5Pos {
        position: absolute;
        top: 0%;
        left: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem6Pos {
        position: absolute;
        top: 20%;
        right: 0;

        img {
            @media (max-width: 992px) {
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    // .logoPanel {
    //     min-height: 60vh;

    //     @media (max-width: 992px) {
    //         // font-size: 30px;
    //     }

    //     @media (max-width: 677px) {
    //         min-height: 30vh;
    //     }
    // }

    .partnerLogoBg {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
        filter: grayscale(1);
        width: 180px;

        &:hover {
            filter: grayscale(0);
            transition: 800ms;
        }

        @media (max-width: 992px) {
            // font-size: 30px;
        }

        @media (max-width: 677px) {
            width: 150px;
            filter: grayscale(0);
        }
    }

    .downloadDiv {
        min-height: 100vh;

        @media (max-width: 992px) {
            min-height: auto;
        }

        @media (max-width: 677px) {
            min-height: auto;
        }
    }

    .newsletterBg {
        background-image: url(../img/home/newsletterBg.png);
        background-size: 100% 100%;
        min-height: 31vh;

        @media (max-width: 992px) {
            min-height: 29vh;
            border-radius: 30px;
        }
    }

    .newsletterTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #050408;

        @media (max-width: 992px) {
            font-size: 24px;
            line-height: 34px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .newsletterDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
        color: #050408;

        @media (max-width: 992px) {
            font-size: 15px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .newsletterBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 180px;

        @media (max-width: 300px) {
            width: 100%;
        }


        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    .subscribe-textBoxBg {
        background: rgba(5, 5, 5, 0.15);
        border-radius: 80px;
        height: 50px;
        border: 1px solid rgba(5, 5, 5, 0.15);
        line-height: 30px;
        text-align: left;
        color: black;

        @media (max-width: 677px) {
            height: 40px;
        }
    }

    .subscribe-textBox {
        background: transparent;
        border-radius: 80px;
        height: 50px;
        border: none;
        width: 350px;
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        color: black;

        @media (max-width: 677px) {
            height: 40px;
            width: 100%;
        }
    }

    .subscribeBtnPos {
        position: absolute;
        top: 10%;
        right: 0;

        @media (max-width: 992px) {
            position: relative;
            margin: 20px auto;
            text-align: center;

        }
    }

    .newsletterSubscribeBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 150px;
        border: none;
        margin: auto;

        @media (max-width: 300px) {
            width: 100%;
        }


        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 20px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    .carousel .control-next.control-arrow:before {
        content: url(../img/home/sliderNext.png) !important;
        border: none;
    }

    .carousel .control-prev.control-arrow:before {
        content: url(../img/home/sliderPrev.png) !important;
        border: none;
    }

    .carousel .control-next.control-arrow {
        right: -3% !important;
    }

    .carousel .control-prev.control-arrow {
        left: -3% !important;
    }

}