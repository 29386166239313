#contactUs {
    background-color: black;

    .title-section {
        min-height: 35vh;
        background-image: url(../img/aboutUs/titleBg.png);
        background-size: 100% 100%;
        background-position: top;
        padding-top: 10vh;
    }

    .titleSectionText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 80px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 50px;
        }
    }

    .contactUs-liveBg {
        background-color: black;
        padding-top: 10vh;
    }

    .header-section {
        min-height: 90vh;
        background-position: top;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 70px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 70px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 20px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .enquiryFormDiv {
        background: #FFFFFF;
        border: 1.5px solid rgba(255, 255, 255, 0.2);
        border-radius: 15px;
    }

    .enquiryFormTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 52px;
        color: #050408;

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .contactUs-textBox {
        background: #EAEAEA;
        border-radius: 30px;
        height: 50px;
        border: none !important;

        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        color: black;

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .subscribe-textBox {
        background: rgba(5, 5, 5, 0.15);
        border-radius: 80px;
        height: 60px;
        border: 1px solid rgba(5, 5, 5, 0.15);

        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        color: black;

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .contactUs-textArea {
        background: #EAEAEA;
        border-radius: 30px;
        padding: 1rem 12px;

        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        color: black;

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .dropdownCountryCode {
        background: #EAEAEA;
        border-radius: 30px;
        height: 50px;

        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 200;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        color: black;

        @media (max-width: 677px) {
            font-size: 10px;
            line-height: 10px;
        }
    }

    .submitFormBtn {
        background: #CAB48E;
        border-radius: 50px;
        border-color: transparent;

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    .width-20 {
        width: 25%;
    }

    .width-80 {
        width: 75%;
    }

    .contactUs-iconSize {
        width: 30px;

        @media (max-width: 677px) {
            width: 20px;
        }
    }

    #iframe-map {
        height: 60vh;
    }

    .contactUs-newsletterBg {
        background-image: url(../img/contactUs/newsletterBg.png);
        background-size: 100% 100%;
        background-position: center;
    }

    .newsletterBg {
        background-image: url(../img/home/newsletterBg.png);
        background-size: 100% 100%;
        min-height: 28vh;

        @media (max-width: 992px) {
            min-height: 29vh;
            border-radius: 30px;
        }
    }

    .newsletterTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 2.2vw;
        line-height: 2.5vw;
        color: #050408;

        @media (max-width: 992px) {
            font-size: 35px;
            line-height: 48px;
        }

        @media (max-width: 677px) {
            font-size: 22px;
            line-height: 26px;
        }
    }

    .newsletterDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #050408;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 18px;
        }
    }

    .newsletterBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 220px;

        @media (max-width: 300px) {
            width: 100%;
        }


        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 15px;
                line-height: 18px;
            }
        }
    }

    .newsletterSubscribeBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 220px;
        border: none;
        margin: auto;

        @media (max-width: 300px) {
            width: 100%;
        }


        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }

            @media (max-width: 677px) {
                font-size: 15px;
                line-height: 18px;
            }
        }
    }

    .subscribeBtnPos {
        position: absolute;
        top: 10%;
        right: 0;

        @media (max-width: 992px) {
            position: relative;
            margin: 20px auto;
            text-align: center;

        }

    }

    .sideItem1Pos-contactUs {
        position: absolute;
        top: 0%;
        right: 0;
        width: 30%;
        z-index: 0;

        img {
            z-index: 0;

            @media (max-width: 992px) {
                // font-size: 30px;
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem2Pos-contactUs {
        position: absolute;
        top: 50%;
        left: 0;
        width: 30%;
        z-index: 0;

        img {
            z-index: 0;

            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .react-tel-input .form-control {
        background: transparent;
        border: none;
        height: 100%;
        padding-left: 60px;
    }

    .react-tel-input .flag-dropdown {
        border-radius: 30px 0 0 30px;
    }

    .react-tel-input .flag-dropdown.open {
        border-radius: 30px 0 0 30px;
    }

    .react-tel-input .flag-dropdown.open .selected-flag {
        border-radius: 30px 0 0 30px;
    }

    .react-tel-input .selected-flag {
        border-radius: 30px 0 0 30px;
        width: 50px;
        padding: 0 0 0 15px;
    }
}