#yeebetLive {
    background-color: black;

    .title-section {
        min-height: 35vh;
        background-image: url(../img/aboutUs/titleBg.png);
        background-size: 100% 100%;
        background-position: top;
        padding-top: 10vh;
    }

    .alignPosCenter {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carousel .control-dots .dot {
        width: 25px;
        border-radius: 50px;
    }

    .carousel .control-dots .dot.selected {
        width: 50px;
        border: 50px;
        background: #CAB48E;
    }

    .titleSectionText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 80px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 40px;
        }
    }

    .learnMoreBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 180px;
        margin: auto;

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 40px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 12px;
                line-height: 40px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 40px;
            }
        }
    }

    .demoBtn {
        background: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        width: 180px;
        margin: auto;

        span {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            color: #050408;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 40px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 40px;
            }
        }
    }

    .header-section {
        min-height: 100vh;
        background-image: url(../img/yeebetLive/header-Bg.png);
        background-size: 100% 100%;
        background-position: top;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .liveCasino-bg {
        min-height: 100vh;
        background-color: black;
        background-image: url(../img/yeebetLive/liveCasinoBg.png);
        background-size: cover;
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 35px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 27px;
            line-height: 35px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 22px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .liveCasino-container {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
    }

    .yeebetLiveAPIImg {
        width: 70%;
    }

    .casinoFeatured-bg {
        min-height: 100vh;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .casinoFeatured-container {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
        margin: auto;

        img {
            width: 80%;
        }

    }

    .casinoFeatured-text {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        text-align: center;
        text-transform: capitalize;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 12px;
            line-height: 140%;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .walletBoxDiv {
        // min-height: 180px;

        img {
            width: 300px;
            height: 150px;

            @media (max-width: 992px) {
                width: 150px;
                height: 150px;
            }
        }
    }

    .whatWeDo-container {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
        min-height: 400px;
        position: absolute;
        width: 90%;
        top: -4%;
        left: -7%;
        margin: auto;
        margin-left: 30px;
    }

    .secondLayerPromo-container {
        width: 90%;
        min-height: 400px;
        background: #141316;
        border-radius: 15px;
        margin: auto;
        margin-left: 30px;

        @media (max-width: 992px) {
            margin: auto;
            margin-left: 30px;
        }
    }

    .whatwedo-logo {
        width: 60px;

        @media (max-width: 992px) {
            width: 60px;
        }

        @media (max-width: 677px) {
            width: 60px;
        }
    }

    .whatwedo-title {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 23px;
        }
    }

    .whatwedo-desc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 22px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .sideItem1Pos-about {
        position: absolute;
        top: -40%;
        right: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem2Pos-about {
        position: absolute;
        top: -25%;
        left: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .licensed-By {
        min-height: 100vh;
        background-image: url(../img/aboutUs/licensedByBg.png);
        background-size: cover;
        background-position: center;
    }

    .revenue_bg {
        background-color: black;
    }

    .sideItem3Pos-about {
        position: absolute;
        top: 0%;
        right: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem4Pos-about {
        position: absolute;
        top: 40%;
        left: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem5Pos-about {
        position: absolute;
        top: 40%;
        right: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem6Pos-about {
        position: absolute;
        bottom: -20%;
        left: 0;

        img {
            @media (max-width: 992px) {
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .currency-container {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
    }

    .revenueContainer {
        background-image: url(../img/yeebetLive/revenue-container.png);
        background-size: 100% 100%;
        min-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 992px) {
            min-height: auto;
        }

        img {
            width: 60px;
        }
    }

    .revenue-title {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 35px;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 35px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .revenue-desc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #FFFFFF;

        @media (max-width: 677px) {
            font-size: 12px;
        }
    }

    .market-Bg {
        background-color: black;
        background-image: url(../img/yeebetLive/marketBg.png);
        background-size: 100% 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 992px) {
            background-size: cover;
            background-position: center;
        }

    }

    .semicolon-color {
        font-family: 'Poppins-Bold';
        font-style: italic;
        font-weight: 700;
        font-size: 27px;
        line-height: 35px;
        text-align: center;
        color: #CAB48E;

        @media (max-width: 992px) {
            font-size: 27px;
            line-height: 35px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .marketText {
        font-family: 'Poppins-Bold';
        font-style: italic;
        font-weight: 700;
        font-size: 27px;
        line-height: 35px;
        text-align: center;
        color: #fff;

        @media (max-width: 992px) {
            font-size: 27px;
            line-height: 35px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .mapDiv {
        min-height: 100vh;
    }

    #mapVideo {
        min-width: 100%;
        min-height: 100vh;
        object-fit: cover;
    }

    .officePos {
        top: 8%;
        left: 0;
        right: 0;
        margin: auto;
    }

    .countryPos {
        bottom: 8%;
        left: 0;
        right: 0;
        margin: auto;
    }



}