#privacyPolicy {

    background-color: black;
    background-image: url(../img/privacy/main_bg.png);
    background-size: cover;
    background-position: center;
    padding-top: 12vh;

    @media (max-width: 992px) {
        padding-top: 10vh;
    }

    .title-section {
        min-height: 35vh;
        background-image: url(../img/aboutUs/titleBg.png);
        background-size: 100% 100%;
        background-position: top;
        padding-top: 10vh;

        @media (max-width: 992px) {
            background-size: cover;
            background-position: center;
        }
    }

    .titleSectionText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 80px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 45px;
        }
    }


    .privacyPolicyBg {
        min-height: 100vh;
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 70px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 27px;
            line-height: 70px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .headerTitle_Gold {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 70px;
        text-align: center;
        color: #CAB48E;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 70px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 22px;
        }
    }

    .tableBg {
        background-image: url(../img/privacy/tableBg.png);
        background-size: 100% 100%;
        background-position: top;

        @media (max-width: 992px) {
            background-size: cover;
            background-position: center;
        }
    }

    .accordion__button {
        background-color: transparent !important;
    }

    .accordionTitle {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #CAB48E;

        @media (max-width: 667px) {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .accordionSideItemText {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #CAB48E;
        width: fit-content;

        @media (max-width: 667px) {
            font-weight: 400;
            font-size: 12px;
        }
    }

    .accordionTitle-inactive {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #fff;

        @media (max-width: 667px) {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .accordionSideItemText-inactive {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #fff;

        @media (max-width: 667px) {
            font-weight: 400;
            font-size: 12px;
        }
    }


    .accordionContent {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 22px;
        }
    }


    .accordion__button {
        background: transparent
    }

    .accordion__panel {
        padding-left: 8px;
        padding-top: 4px !important;
    }

    // .accordion__panel {
    //     background-color: linear-gradient(30.79deg, #FFFFFF 6.36%, rgba(255, 255, 255, 0) 96.56%);
    //     filter: drop-shadow(0px 4px 20px rgba(33, 25, 118, 0.1));
    // }

    // .accordion-item {
    //     border: 5px solid #293C92;
    //     border-width: 0 0px 0 5px;
    //     background: linear-gradient(30.79deg, #FFFFFF 6.36%, rgba(255, 255, 255, 0) 96.56%);
    //     filter: drop-shadow(0px 4px 20px rgba(33, 25, 118, 0.1));
    // }

    // .accordion-item-inactive {
    //     background: linear-gradient(30.79deg, #FFFFFF 6.36%, rgba(255, 255, 255, 0) 96.56%);
    //     filter: drop-shadow(0px 4px 20px rgba(33, 25, 118, 0.2));
    // }


    .accordion__button:before {
        display: none;
    }

    .lh-1 {
        line-height: 1;
    }

    .accordion__button[aria-expanded='true'] .accordionIcon,
    .accordion__button[aria-selected='true'] .accordionIcon {
        transform: rotate(90deg);
    }

    .accordion__button::before {
        display: none !important;
    }

    .borderBottomWhite {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
    }

    .borderTop {
        border-top: 1px solid #CAB48E;
    }

    .tableTitleRow {
        background: rgba(255, 255, 255, 0.2);
    }

}