.logo-header {
    width: 25%;

    @media screen and (max-width: 767px) {
        width: 40%;
    }

    @media screen and (min-width: 1300px) {
        width: 130px;
    }
}

.sidebar-hamburger {
    z-index: 999;
}

@mixin transition {
    @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
            transition: transform 1s #{$i * .08}s cubic-bezier(.29, 1.4, .44, .96);
        }
    }
}

@mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}

.navbar {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

$main: #1184F0;
$text: #FEFEFE;
$contrast: darken($main, 10%);

.msg {
    font-family: 'Pacifico', cursive;
    font-size: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    align-items: center;
    color: $text;
    background-color: $main;
}

.drawer-list {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    transform: translate(120vw, 0);
    /* ie workaround */
    -ms-transform: translatex(-120vw);
    box-sizing: border-box;
    pointer-events: none;
    padding-top: 125px;
    transition: width 475ms ease-out, transform 450ms ease, border-radius .8s .1s ease;
    border-bottom-left-radius: 100vw;
    backdrop-filter: blur(100px);
    background-color: rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 767px) {
        top: -20px;
    }

    &.dark {
        background: rgba(5, 1, 14, 0.8);
    }

    &.light {
        // background-image: url('../img/background/light-drawer-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    @media screen and (max-width: 767px) {
        padding-top: 80px;
    }

    @media (min-width: 768px) {
        width: 100vw;
    }

    ul {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: auto;
        overflow-x: hidden;
        pointer-events: auto;
    }

    li {
        list-style: none;
        text-transform: uppercase;
        pointer-events: auto;
        white-space: nowrap;
        box-sizing: border-box;
        transform: translatex(100vw);
        /* ie workaround */
        -ms-transform: translatex(-100vw);

        &:last-child {
            margin-bottom: 2em;
        }

        a {
            text-decoration: none;
            text-align: center;
            display: block;
            padding: 1rem;
            // font-size: calc(24px - .5vw);

            &.dark {
                color: $text;
            }

            &.light {
                color: #575757;
            }

            @media (min-width: 768px) {
                text-align: right;
                padding: .5rem;
            }

            &:hover {
                cursor: pointer;
                // @include alpha-attribute('background-color', rgba($main, 0.5), white);
            }
        }
    }
}

.hamburger-container {
    // position: fixed;
    // top: 1.625rem;
    // right: 15px;

    @media screen and (max-width: 767px) {
        top: 0.5rem;
        right: 15px;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        top: 0.5rem;
        right: 15px;
    }

    input.hamburger {
        display: none;

        &:checked {
            &~.drawer-list {
                transform: translatex(0);
                border-bottom-left-radius: 0;

                li {
                    transform: translatex(0);
                    @include transition;

                    a {
                        padding-right: 15px;
                    }
                }

                @media screen and (max-width: 991px) {
                    transform: translate(1rem);
                }
            }

            &~label {
                >i {
                    background-color: transparent;
                    transform: rotate(90deg);

                    &:before {
                        transform: translate(-50%, -50%) rotate(45deg);
                        background-color: #fff;
                    }

                    &:after {
                        transform: translate(-50%, -50%) rotate(-45deg);
                        background-color: #fff;
                    }
                }

                close {
                    color: $text;
                    width: 100%;
                }

                open {
                    color: rgba(0, 0, 0, 0);
                    width: 0;
                }
            }
        }
    }

    label.hamburger {
        z-index: 9999;
        position: relative;
        display: block;
        height: 25px;
        width: 35px;

        @media screen and (max-width: 767px) {
            width: 10vw;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
            width: 5vw;
        }

        &:hover {
            cursor: pointer;
        }

        text {

            close,
            open {
                text-transform: uppercase;
                font-size: .8em;
                align-text: center;
                position: absolute;
                transform: translateY(50px);
                text-align: center;
                overflow: hidden;
                transition: width .25s .35s, color .45s .35s;
            }

            close {
                color: rgba(0, 0, 0, 0);
                right: 0;
                width: 0;
            }

            open {
                color: $text;
                width: 100%;
            }
        }

        >i {
            position: absolute;
            width: 100%;
            height: 3px;
            top: 50%;

            pointer-events: auto;
            transition-duration: .35s;
            transition-delay: .35s;
            background-color: #f3fff3;

            &.dark {
                background-color: #f3fff3;
            }

            &.light {
                background-color: #f3fff3;

                &.homeHamburger {
                    &.unchecked {
                        background-color: #f3fff3;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                background-color: #f3fff3;
                height: 2px;
            }

            &.dark {
                background-color: transparent;

                &:before,
                &::after {
                    background-color: #f3fff3;
                }
            }

            &.light {
                background-color: transparent;

                &:before,
                &::after {
                    background-color: #f3fff3;
                }

                &.homeHamburger {
                    &.checked {

                        &:before,
                        &::after {
                            background-color: #f3fff3;
                        }
                    }

                    &.unchecked {

                        &:before,
                        &::after {
                            background-color: #f3fff3;
                        }
                    }
                }
            }

            &:before,
            &:after {
                position: absolute;
                display: block;
                width: 100%;
                height: 3px;
                left: 50%;
                content: "";
                transition: transform 0.35s;
                transform-origin: 50% 50%;

                @media screen and (max-width: 767px) {
                    background-color: #f3fff3;
                    height: 2px;
                }
            }

            &:before {
                transform: translate(-50%, -10px);
                background-color: #f3fff3;
            }

            &:after {
                transform: translate(-50%, 10px);
                background-color: #f3fff3;
            }
        }
    }
}

.bg-white {
    .hamburger-container {
        .text-white {
            color: #f3fff3 !important;
        }

        .unchecked {
            background-color: #f3fff3 !important;

            &::before,
            &::after {
                background-color: #f3fff3 !important;
            }
        }
    }
}

.navbar-transparent {
    .hamburger-container {
        .text-white {
            color: #f3fff3 !important;
        }

        .homeHamburger.unchecked {
            background-color: #f3fff3 !important;

            &::before,
            &::after {
                background-color: #f3fff3 !important;
            }
        }
    }
}

body {
    scrollbar-base-color: rgb(19, 138, 114);
    scrollbar-3dlight-color: #fff;
    scrollbar-highlight-color: #1abc9c;
    scrollbar-track-color: #fff;
    scrollbar-arrow-color: #1abc9c;
    scrollbar-shadow-color: #1abc9c;
    scrollbar-dark-shadow-color: #1abc9c;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.icon {
    display: inline-block;
    width: 5vw;
    height: 4vw;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.btn-login {
    border-radius: 100rem;
    // padding: 0.5rem 2rem !important;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.25vw;
    border: solid 1.5px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    transition: all 1s;
    text-transform: capitalize;

    &.dark {
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #39AAED, #31D599);
        box-shadow: 2px 1000px 1px #000328 inset;
        color: #fff;
    }

    &.light {
        background: #F3FFFE;
        border: solid 1.5px #31D599;
        color: #05010E;
    }

    &:hover {
        &.light {
            background: linear-gradient(349.56deg, #39AAED 7.51%, #31D599 85.22%);
            color: #f3fff3;
        }

        box-shadow: none;
        border-color: transparent;

    }

    @media screen and (max-width: 767px) {
        font-size: 2.833vw;
        line-height: 105%;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 1.833vw;
        line-height: 105%;
    }
}

.btn-register {
    border-radius: 100rem;
    padding: 0.5rem 2rem !important;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.25vw;
    color: #fff;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 1.5px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #39AAED, #31D599);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: none;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
        border-color: transparent;

        &.dark {
            box-shadow: 2px 1000px 1px #000328 inset;
        }

        &.light {
            background: #f3fff3;
            border-color: #31D599;
            color: #05010E;
        }
    }

    @media screen and (max-width: 767px) {
        font-size: 2.833vw;
        line-height: 105%;
        padding: 0.5rem 1rem !important;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 1.833vw;
        line-height: 105%;
    }
}

.drawer-list-group {
    li {
        a {
            &.dark {
                color: $text;
            }

            &.light {
                color: #575757 !important;
            }
        }

        &:hover {
            // background-image: url('../img/background/listBg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;

            a {
                color: #ffbb46 !important;
            }

            svg {
                color: #ffbb46 !important;
            }
        }
    }
}

.navbar-translate {
    z-index: 9999 !important;
}

.navbar-nav {
    z-index: 9999 !important;
}

.react-switch {
    z-index: 9999 !important;

    &.light {
        .react-switch-bg {
            background: linear-gradient(270deg, rgba(0, 54, 107, 0.2) 0%, rgba(0, 112, 154, 0) 75%), #FFFFFF !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        }
    }
}

.lang-dropdown-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: relative;

    .dropdown {
        border-radius: 3px;
        width: 100%;
        // width: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .fa-angle-down {
        position: relative;
        top: 2px;
        font-size: 1.3rem;
        transition: transform 0.3s ease;
    }

    .rotate-dropdown-arrow {
        transform: rotate(-180deg);
    }

    .lang-dropdown-menu,
    .lang-dropdown-menu-lg {
        display: none;
        border-radius: 4px;
        margin-top: 8px;
        width: 15vw;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        // background: #fafafa;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .lang-menu-container {
        height: 40vh;
        overflow-y: scroll;
        flex-direction: column;
        display: flex;
    }

    .lang-dropdown-menu span,
    .lang-dropdown-menu-lg span {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .lang-dropdown-menu span:hover,
    .lang-dropdown-menu-lg span:hover {
        background: rgba(255, 255, 255, 0.2);
    }

    #openDropdown:checked+.lang-dropdown-menu,
    #openDropdown-lg:checked+.lang-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}

.top-menu {
    overflow: visible !important;

    li {
        display: inline;
    }
}

.mobile-menu-list {
    li {
        // border-bottom: 1px solid #2e3f52;
        cursor: pointer;
    }



    li a {
        display: block;
        width: inherit;
        position: relative;
        line-height: 20px;
        height: 40px;
        padding: 10px;
        transition: all .51s ease;

        // color: red !important;
        &.dark {
            color: black;
        }

        &.light {
            color: #575757 !important;
        }
    }

    .godwin {
        background-color: #28394A;
    }

    li>a:hover {
        // background-image: url('../../assets/img/background/menu-active.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top;
        // background-color: #36cde8;
        color: #31D599;
    }

    .dropdown-mobile-submenu>a:hover {
        // background-color: #0bb8d3;
        // color: #637991;
        // background-image: url('../../assets/img/background/menu-active.png');
        background-repeat: no-repeat;
        background-size: cover;
        // background-color: #36cde8;
        color: #31D599;
    }

    a {
        text-decoration: none !important;
    }

    .dropdown-mobile-submenu,
    .dropdown-mobile {
        a {
            &.active {
                svg {
                    // transform: rotate(-180deg);
                }
            }
        }

        svg {
            top: 5px;
            position: relative;
        }
    }

    .dropdown-mobile-menu {
        margin: 0px;
        padding: 0px;
    }

    .dropdown-mobile-menu li {
        background-color: transparent;

        &:last-child {
            margin-bottom: 1em;
        }
    }

    .level-1,
    .level-2,
    .level-3,
    .level-4 {
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.52) inset;
    }

    .level-1 a:before {
        content: "  ";
        white-space: pre;
    }

    .level-2 a:before {
        content: "    ";
        white-space: pre;
    }

    .level-3 a:before {
        content: "      ";
        white-space: pre;
    }

    .level-4 a:before {
        content: "        ";
        white-space: pre;
    }

    .level-1 {
        // background-color: #202f40;
        // background-image: url('../img/background/submenu-active.png');
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        margin-top: 1em;
    }

    .level-2 {
        // background-color: #182838;
        margin-top: 1em;
    }

    .level-3 {
        background-color: #122436;
    }

    .level-4 {
        background-color: #0e2031;
    }
}

.switch-mobile {
    position: absolute;
    bottom: 5%;
    left: 5%;
}

.language-mobile {
    .language-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        cursor: pointer;
        overflow: hidden;

        &:hover,
        &:active,
        &:focus {
            background: transparent;
            border: 1px solid transparent;
            outline: none;
        }
    }
}

.dropdown-mobile .language-options {
    // margin: 5px 0 0;
    // padding: 1rem 0;
    border-radius: 3px;
    border: 1px solid #000;
    box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.15);
    width: 100%;
}

.language-options--opened {
    display: block;
}

.language-list {
    margin: 0;
    height: 100%;
}

.language-option {
    display: flex;
    align-items: center;
    // padding: 0.5rem 1rem;
    cursor: pointer;
    // font-size: 1.125rem;
    // font-weight: 400;
    transition: ease 0.25s;
}

.language-option:hover {
    background: #31D599;
    border-radius: 5px;
}

.language-option:hover .language-checkbox {
    background: #fff;
}

.mt-xl-10vh {
    @media screen and (min-width: 1800px) {
        margin-top: 10vh;
    }
}

@media screen and (min-width: 992px) {
    .navbar-nav .nav-item:not(:last-child) {
        margin-right: 1vw;
    }
}

.lang-dropdown-container .lang-menu-container {
    &::-webkit-scrollbar {
        width: .4em;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        overflow: visible;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, .2);
    }
}