#aboutUs {
    background-color: black;

    .title-section {
        min-height: 35vh;
        background-image: url(../img/aboutUs/titleBg.png);
        background-size: 100% 100%;
        background-position: top;
        padding-top: 10vh;

        @media (max-width: 992px) {
            background-size: cover;
            background-position: center;
        }
    }

    .titleSectionText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 80px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 50px;
        }
    }

    .header-section {
        min-height: 100vh;
        background-image: url(../img/aboutUs/headerBg.png);
        background-size: 100% 100%;
        background-position: top;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .what-we-do {
        min-height: 100vh;
        background-color: black;
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 70px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 30px;
            line-height: 40px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 20px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .whatWeDo-container {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
        min-height: 380px;
        position: absolute;
        width: 90%;
        top: -4%;
        left: -7%;
        margin: auto;
        margin-left: 30px;

        @media (max-width: 992px) {
            margin: auto;
            margin-left: 30px;
        }

        @media (max-width: 667px) {
            min-height: 300px;
        }
    }

    .secondLayer-container {
        width: 90%;
        min-height: 380px;
        background: #141316;
        border-radius: 15px;
        margin: auto;
        margin-left: 30px;

        @media (max-width: 992px) {
            margin: auto;
            margin-left: 30px;
        }

        @media (max-width: 667px) {
            min-height: 300px;
        }
    }

    .whatwedo-logo {
        width: 40px;

        @media (max-width: 992px) {
            width: 40px;
        }

        @media (max-width: 677px) {
            width: 40px;
        }
    }

    .whatwedo-title {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 52px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 22px;
            line-height: 52px;
        }

        @media (max-width: 677px) {
            font-size: 17px;
            line-height: 25px;
        }
    }

    .whatwedo-desc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 20px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .sideItem1Pos-about {
        position: absolute;
        top: -40%;
        right: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem2Pos-about {
        position: absolute;
        top: -25%;
        left: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .licensed-By {
        min-height: 100vh;
        background-image: url(../img/aboutUs/licensedByBg.png);
        background-size: cover;
        background-position: center;
    }

    .sideItem3Pos-about {
        position: absolute;
        top: 0%;
        right: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem4Pos-about {
        position: absolute;
        top: 40%;
        left: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem5Pos-about {
        position: absolute;
        top: 40%;
        right: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem6Pos-about {
        position: absolute;
        bottom: -20%;
        left: 0;

        img {
            @media (max-width: 992px) {
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }


    .social-responsibility {
        min-height: 100vh;
    }

    .partnerLogoBg {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
        filter: grayscale(1);
        width: 180px;

        &:hover {
            filter: grayscale(0);
            transition: 800ms;
        }

        @media (max-width: 992px) {
            // font-size: 30px;
        }

        @media (max-width: 677px) {
            width: 120px;
            filter: grayscale(0);
        }
    }

    .socialRespon-container {
        background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.21) -8.95%, rgba(255, 255, 255, 0.006) 114%);
        backdrop-filter: blur(50px);
        border-radius: 15px;
        min-height: 500px;
        position: absolute;
        width: 90%;
        top: -4%;
        left: -7%;
        margin: auto;
        margin-left: 30px;
    }

    .secondLayerRS-container {
        width: 90%;
        min-height: 500px;
        background: #141316;
        border-radius: 15px;
        margin: auto;
        margin-left: 30px;

        @media (max-width: 992px) {
            margin: auto;
            margin-left: 30px;
        }
    }

    .mapDiv {
        min-height: 100vh;
    }

    #mapVideo {
        min-width: 100%;
        min-height: 100vh;
        object-fit: cover;
    }

    .officePos {
        top: 8%;
        left: 0;
        right: 0;
        margin: auto;
    }

    .countryPos {
        bottom: 8%;
        left: 0;
        right: 0;
        margin: auto;
    }

    .contactUs-iconSize {
        width: 25px;

        @media (max-width: 667px) {
            width: 20px;
        }
        // @media (max-width: 992px) {
        //     margin: auto;
        //     margin-left: 30px;
        // }
    }

    .locationTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 22px;
        }

        @media (max-width: 667px) {
            font-size: 18px;
        }
    }

    .locationDetailsDiv {
        background: rgba(0, 0, 0, 0.8);
        border-radius: 15px;
        width: 100%;
    }

    // .locationDetailsDiv1 {
    //     position: absolute;
    //     top: -35vh;
    //     left: 6%;

    //     @media (max-width: 992px) {
    //         position: relative;
    //     }
    // }

    // .locationDetailsDiv2 {
    //     position: absolute;
    //     top: -35vh;
    //     left: 25%;

    //     @media (max-width: 992px) {
    //         position: relative;
    //     }
    // }

    // .locationDetailsDiv3 {
    //     position: absolute;
    //     top: -35vh;
    //     right: 6%;

    //     @media (max-width: 992px) {
    //         position: relative;
    //     }
    // }

    .contactUs-detailsText {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.7vw;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 13px;
            line-height: 20px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 22px;
        }
    }

    .countryActive {
        transform: translateY(-0.5em);
    }

    .countryInactive {
        &:hover {
            transform: translateY(-0.5em);
        }
    }
}